import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { log } from 'util';

@Component({
  selector: 'app-cabecera',
  templateUrl: './cabecera.component.html',
  styleUrls: ['./cabecera.component.scss']
})
export class CabeceraComponent implements OnInit {

  usuario: string;
  tipoUsuario: number;

  registrosGeneralesActive: boolean = false;
  inicioAdmActive: boolean = false;

  registrosActive: boolean = false;

  inicioActive: boolean = false;
  estadisticasActive: boolean = false;

  parametrosActive: boolean = false;
  isCelular: boolean = false;

  constructor(
    private router: Router
  ) {

    this.usuario = localStorage.getItem('usu_nombre');
    this.tipoUsuario = parseInt(localStorage.getItem('usu_tipo'));

    var ua = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      // this.mostrarMsg("success", "Éxito", "Entro un celular.");
      this.isCelular = true;
    }

    this.setMenuActive(this.router.url);

  }

  ngOnInit() {
  }

  setMenuActive(menu) {

    switch (menu) {

      case "/registrosGenerales":
        this.registrosGeneralesActive = true;
        this.router.navigateByUrl("/registrosGenerales");
        break;

      case "/inicioAdm":
        this.inicioAdmActive = true;
        this.router.navigateByUrl("/inicioAdm");
        break;

      case "/parametros":
        this.parametrosActive = true;
        this.router.navigateByUrl("/parametros");
        break;

      case "/reporteResumen":
        this.inicioActive = true;
        this.router.navigateByUrl("/reporteResumen");
        break;

      case "/estadisticas":
        this.estadisticasActive = true;
        this.router.navigateByUrl("/estadisticas");
        break;

    }

  }

  cerrarSesion() {

    var miElto = document.getElementsByClassName("sinFondo")[0];
    miElto.className = "fondo";

    localStorage.clear();
    this.router.navigateByUrl('/');
  }
}
