import { Component, OnInit, TemplateRef, ViewChild, Input, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegistrosService } from '../../../services/registros.service';
import { ReportesService } from '../../../services/reportes.service';
import { SeccionesService } from '../../../services/secciones.service';
import { SucursalesService } from '../../../services/sucursales.service';
import { UxService } from '../../../services/ux.service';

import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { log } from 'util';
import { Router } from '@angular/router';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { esLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { CabeceraComponent } from 'src/app/base/cabecera/cabecera.component';

import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ExcelService } from 'src/app/project/services/excel.service';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';

@Component({
  selector: 'app-reporte-resumen',
  templateUrl: './reporteResumen.component.html',
  styleUrls: ['./reporteResumen.component.scss'],
  providers: [ExcelService],
})
export class ReporteResumenComponent implements OnInit {

  cliente: number;
  usuario: number;
  seccion: any;
  @ViewChild('tablaCasos') table: any;
  listData: any = [];
  rows: any = [];
  sucursalSelect: number;
  seccionSelect: number;
  nombreSucursalSelect: string = "";

  showError: boolean = false;
  tipoError: string = "";
  msgError: string = "";
  iconError: string = "";
  panelError: string = "";

  reorderable: boolean;
  fechaActualizacion: Date;
  submitted = false;
  tiposReporte: any;
  secciones: any;
  sucursales: any;

  totalCasosNormalAtencionSucursal: any;
  totalCasosFueraAtencionSucursal: any;
  totalCasosPerdidaAtencionSucursal: any;
  totalCasosNormalPrepSucursal: any;
  totalCasosFueraPrepSucursal: any;
  totalCasosPerdidaPrepSucursal: any;

  totalCasosSucursal: any;

  fechaInicio: any;
  fechaTermino: any;

  fechaTerminoString;
  fechaInicioString;

  mostrarTabla: boolean = false;
  data: any;
  isCelular: boolean = false;


  locale = 'es';

  datosGrafico;
  datosGraficoPrep;

  // Variables componente cargando  

  loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };

  constructor(
    private formBuilder: FormBuilder,
    private reportesService: ReportesService,
    private seccionesService: SeccionesService,
    private sucursalesService: SucursalesService,
    private router: Router,
    private ux: UxService,
    private localeService: BsLocaleService,
    private excelService: ExcelService
  ) {

    var ua = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      // this.mostrarMsg("success", "Éxito", "Entro un celular.");
      this.isCelular = true;
    }

    this.cliente = parseInt(localStorage.getItem('usu_cliente'), 10);
    this.usuario = parseInt(localStorage.getItem('usu_id'), 10);

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "sinFondo";
    }

    this.reorderable = true;
    this.sucursalSelect = -1;

    defineLocale(this.locale, esLocale);
    this.localeService.use(this.locale);

    var currentUtcTime = new Date();
    this.fechaActualizacion = new Date(currentUtcTime.toLocaleString('en-CL', { timeZone: 'America/Lima' }));

  }

  ngOnInit() {
    this.getSucursales((err, dataSucursales) => {
      if (err) {
        this.ux.alerta(err.message);
      } else {
        this.sucursales = dataSucursales;
      }
    });
  }

  getReporteResumen() {

    var day_as_milliseconds = 86400000;
    let diff_in_millisenconds = this.fechaTermino - this.fechaInicio
    var diff_in_days = diff_in_millisenconds / day_as_milliseconds;

    this.nombreSucursalSelect = "";
    this.totalCasosNormalAtencionSucursal = 0;
    this.totalCasosFueraAtencionSucursal = 0;
    this.totalCasosPerdidaAtencionSucursal = 0;

    this.totalCasosNormalPrepSucursal = 0;
    this.totalCasosFueraPrepSucursal = 0;
    this.totalCasosPerdidaPrepSucursal = 0;
    this.totalCasosSucursal = 0;

    if (this.fechaInicio == null || this.fechaInicio == undefined || this.fechaTermino == null || this.fechaTermino == undefined) {
      this.ux.notificacion('Debe definir un rango de fechas para filtrar.', 'warning');
    } else if (this.fechaInicio > this.fechaTermino) {
      this.ux.notificacion('La fecha de término no puede ser menor que la fecha de inicio.', 'warning');
    } else if (diff_in_days > 31) {
      this.ux.notificacion('El rango máximo de búsqueda es 31 días.', 'warning');
    } else {
      this.showCargando();

      let mes = ((this.fechaInicio.getMonth() + 1) <= 9) ? '0' + (this.fechaInicio.getMonth() + 1) : this.fechaInicio.getMonth() + 1;
      let mesTermino = ((this.fechaTermino.getMonth() + 1) <= 9) ? '0' + (this.fechaTermino.getMonth() + 1) : this.fechaTermino.getMonth() + 1;

      let dia = (this.fechaInicio.getDate() <= 9) ? '0' + (this.fechaInicio.getDate()) : this.fechaInicio.getDate();
      let diaTermino = (this.fechaTermino.getDate() <= 9) ? '0' + (this.fechaTermino.getDate()) : this.fechaTermino.getDate();

      this.fechaInicioString = dia + '-' + mes + '-' + this.fechaInicio.getFullYear();
      this.fechaTerminoString = diaTermino + '-' + mesTermino + '-' + this.fechaTermino.getFullYear();

      let param = {
        cliente: this.cliente,
        usuario: this.usuario,
        sucursal: this.sucursalSelect,
        seccion: this.seccionSelect,
        fechaInicio: this.fechaInicio.getFullYear() + '-' + mes + '-' + dia,
        fechaTermino: this.fechaTermino.getFullYear() + '-' + mesTermino + '-' + diaTermino,
      }

      this.reportesService.getEstadisticas(param)
        .subscribe(dataRegistros => {

          this.listData = dataRegistros.reporteSucursal;
          this.totalCasosNormalAtencionSucursal = dataRegistros.totalCasosNormalAtencionSucursal;
          this.totalCasosFueraAtencionSucursal = dataRegistros.totalCasosFueraAtencionSucursal;
          this.totalCasosPerdidaAtencionSucursal = dataRegistros.totalCasosPerdidaAtencionSucursal;

          this.totalCasosNormalPrepSucursal = dataRegistros.totalCasosNormalPrepSucursal;
          this.totalCasosFueraPrepSucursal = dataRegistros.totalCasosFueraPrepSucursal;
          this.totalCasosPerdidaPrepSucursal = dataRegistros.totalCasosPerdidaPrepSucursal;
          
          this.totalCasosSucursal = dataRegistros.totalCasosSucursal;

          this.datosGrafico = {
            labels: ['Normal', 'Fuera de tiempo', 'Perdida'],
            datasets: [
              {
                data: [this.totalCasosNormalAtencionSucursal, this.totalCasosFueraAtencionSucursal, this.totalCasosPerdidaAtencionSucursal],
                backgroundColor: [
                  "#28a745",
                  "yellow",
                  "orange"
                ],
                hoverBackgroundColor: [
                  "#28a745",
                  "yellow",
                  "orange"
                ]
              }]
          };

          this.datosGraficoPrep = {
            labels: ['Normal', 'Fuera de tiempo', 'Perdida'],
            datasets: [
              {
                data: [this.totalCasosNormalPrepSucursal, this.totalCasosFueraPrepSucursal, this.totalCasosPerdidaPrepSucursal],
                backgroundColor: [
                  "blue",
                  "#713B3B",
                  "red"
                ],
                hoverBackgroundColor: [
                  "blue",
                  "#713B3B",
                  "red"
                ]
              }]
          };

          this.hideCargando();

          this.mostrarTabla = true;

        }, err => {
          this.hideCargando();
          this.ux.alerta(err);
          console.log('Error al invocar a reportesService.getReporteResumen: ', err);
        });

    }
  }

  getSucursales(callback) {
    this.showCargando();

    this.sucursalesService.getSucursalesUser(this.cliente, this.usuario)
      .subscribe(dataSucursales => {
        this.hideCargando();

        if (dataSucursales.status === 'ERROR') {
          callback(dataSucursales.error, null);
        } else if (dataSucursales.status === 'OK') {
          callback(null, dataSucursales.data);
        }
      }, err => {
        this.hideCargando();

        console.log('Error al invocar a sucursalesService.getSucursales: ', err);
      });
  }

  onSucursalChange(sucursal) {
    if (sucursal !== '' && sucursal !== -1) {
      this.getSeccionesSucursal(this.cliente, this.sucursalSelect, (err, dataReporte) => {
        if (err) {
          this.ux.alerta(err.message);
        } else {
          this.secciones = dataReporte;
        }
      });
    }
  }

  getSeccionesSucursal(cliente: number, sucural: number, callback) {
    this.showCargando();

    this.seccionesService.getSeccionesSucursal(this.cliente, this.sucursalSelect)
      .subscribe(dataReporte => {

        this.hideCargando();
        if (dataReporte.status === 'ERROR') {
          callback(dataReporte.error, null);
        } else if (dataReporte.status === 'OK') {
          callback(null, dataReporte.data);
        }

      }, err => {
        this.hideCargando();
        console.log('Error al invocar a seccionesService.getSeccionesSucursal: ', err);
      });
  }

  //  *********************************** FUNCIONES DE VISTA ***********************************


  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

  setMenuActive(menu) {

    switch (menu) {
      case "registros":
        this.router.navigateByUrl('/registros');
        break;

      case "reporteSucursales":
        this.router.navigateByUrl('/reporteSucursales');
        break;

      case "reporteSecciones":
        this.router.navigateByUrl('/reporteSecciones');
        break;

      case "reporteBotones":
        this.router.navigateByUrl('/reporteBotones');
        break;

      case "reporteGraficos":
        this.router.navigateByUrl('/reporteGraficos');
        break;

      case "casos":
        this.router.navigateByUrl('/casos');
        break;

      case "reporteResumen":
        this.router.navigateByUrl('/reporteResumen');
        break;

    }

  }

  generarCSV() {

    let cont = 1;

    var csv = 'ID;Sucursal;Cantidad Total;Normales;Fuera de tiempo;Insistencia;Pérdida;Promedio;Rendimiento\n';

    this.listData.forEach(row => {
      if (row != null) {
        csv += cont + ';' + row.suc_nombre + ';' + row.cantTotales + ';' + row.normal + ';' + row.fueraTiempo + ';' + row.insistencia + ';' + row.perdida + ';' + row.promedio + ';' + row.rendimiento;
        csv += '\n';
      }
      cont++;
    });

    this.generarDescarga(csv);

  }

  generarDescarga(csv) {

    this.showCargando();

    const f = new Date();
    const fecha = this.strDate(f.getUTCDate()) + '-' + this.strDate(f.getUTCMonth()) + '-' + f.getUTCFullYear() + '_' +
      this.strDate(f.getUTCHours()) + '_' + this.strDate(f.getUTCMinutes()) + '_' + this.strDate(f.getUTCSeconds());


    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'reporte_resumen_' + fecha + '.csv';
    hiddenElement.click();

    this.ux.notificacion('Informe generado satisfactoriamente.', 'success');

    this.hideCargando();
  }

  strDate(num: number) {
    return ('0' + num).substr(-2);
  }

  descargarReporte() {

    this.showCargando();

    let data = document.getElementById('reporteHoy');

    const options = {
      background: 'white',
      scale: 1,
      allowTaint: true,
      scrollX: 0,
      scrollY: -window.scrollY
    };

    html2canvas(data, options).then((canvas) => {

      var imgWidth = 210;
      var pageHeight = 320;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF('p', 'mm');
      var position = 5;
      var pageData = canvas.toDataURL('image/jpeg', 1.0);
      var imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }

      doc.save('reporte_resumen_' + this.fechaInicioString + "_" + this.fechaTerminoString);

      this.hideCargando();

    });



  }

  createExcel() {

    let fileName = "reporte_" + this.fechaInicioString + "_" + this.fechaTerminoString;

    let dataFinal = [];

    this.listData.forEach(element => {

      let data = {
        Tienda: element.suc_nombre,
        Asis_Normal: element.normal,
        Asis_Fuera_Tiempo: element.fueraTiempo,
        Asis_Perdida: element.perdida,
        Total_Asis: element.cantTotal,
        Rendimiento_Asis: element.rendimiento + " %",

        Ret_Normal: element.normalRetiro,
        Ret_Fuera_Tiempo: element.fueraTiempoRetiro,
        Ret_Perdida: element.perdidaRetiro,
        Total_Ret: element.cantTotalRetiro,
        Rendimiento_Ret: element.rendimientoRetiro + " %",

        Non_Normal: element.secciones[0].normal,
        Non_Fuera_Tiempo: element.secciones[0].fueraTiempo,
        Non_Perdida: element.secciones[0].perdida,
        Total_Non: element.secciones[0].cantTotal,
        Rendimiento_Non: element.secciones[0].rendimiento + " %",
      }

      dataFinal.push(data);

    });

    this.excelService.exportAsExcelFile(dataFinal, fileName);

  }


}
