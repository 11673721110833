import { Component, TemplateRef, OnInit } from '@angular/core';
import { SucursalesService } from '../../../services/sucursales.service';
import { SeccionesService } from '../../../services/secciones.service';
import { UxService } from '../../../services/ux.service';
import { ReportesService } from '../../../services/reportes.service';

import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { Router } from '@angular/router';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { esLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { log } from 'util';
import { RegistrosService } from '../../../services/registros.service';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';

@Component({
  selector: 'app-reporte-secciones',
  templateUrl: './reporteSecciones.component.html',
  styleUrls: ['./reporteSecciones.component.scss']
})
export class ReporteSeccionesComponent implements OnInit {

  rowsCasos: any = [];
  dataRegistros = [];
  registrosCasos = [];
  reorderable: boolean;
  sucursales: any;
  secciones: any;
  cliente: number;
  usuario: number;
  sucursalSelect: number;
  seccionSelect: number;
  seccionSelectFinal: any = [];

  showError: boolean = false;
  tipoError: string = "";
  msgError: string = "";
  iconError: string = "";
  panelError: string = "";

  fechaInicio: any;
  fechaTermino: any;
  mostrarTabla: boolean = false;
  seccion = "";

  locale = 'es';

  // Variables componente graficos

  datosGrafico: any[] = [];

  view: any[] = [550, 400];

  // options
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Acción';
  showYAxisLabel = true;
  yAxisLabel = 'Cantidad';
  isCelular: boolean = false;

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };


  // Variables componente cargando  

  loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };


  constructor(
    private reportesService: ReportesService,
    private seccionesService: SeccionesService,
    private sucursalesService: SucursalesService,
    private registrosService: RegistrosService,
    private ux: UxService,
    private router: Router,
    private localeService: BsLocaleService
  ) {

    defineLocale(this.locale, esLocale);
    this.localeService.use(this.locale);

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "sinFondo";
    }

    this.reorderable = true;
    this.cliente = parseInt(localStorage.getItem('usu_cliente'), 10);
    this.usuario = parseInt(localStorage.getItem('usu_id'), 10);
    this.sucursalSelect = -1;
    this.seccionSelect = -1;

    var ua = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      // this.mostrarMsg("success", "Éxito", "Entro un celular.");
      this.isCelular = true;
    }
  }

  ngOnInit() {
    this.getSucursales((err, dataSucursales) => {
      if (err) {
        this.ux.alerta(err.message);
      } else {
        this.sucursales = dataSucursales;
      }
    });
  }

  getSucursales(callback) {
    this.showCargando();

    this.sucursalesService.getSucursalesUser(this.cliente, this.usuario)
      .subscribe(dataSucursales => {
        this.hideCargando();

        if (dataSucursales.status === 'ERROR') {
          callback(dataSucursales.error, null);
        } else if (dataSucursales.status === 'OK') {
          callback(null, dataSucursales.data);
        }
      }, err => {
        this.hideCargando();
        console.log('Error al invocar a sucursalesService.getSucursalesUser: ', err);
      });
  }

  filtrarReportesSeccion() {

    this.seccionSelectFinal = [];

    if (this.sucursalSelect != null && this.sucursalSelect !== -1 &&
      this.seccionSelect != null && this.seccionSelect !== -1 &&
      this.fechaInicio != null && this.fechaTermino != null) {

      this.ocultarError();

      if (this.fechaInicio <= this.fechaTermino) {

        this.ocultarError();

        let fechaInicio = new Date(this.fechaInicio);
        let fechaTermino = new Date(this.fechaTermino);

        let dia = (this.fechaInicio.getDate() <= 9) ? '0' + (this.fechaInicio.getDate()) : this.fechaInicio.getDate();
        let diaTermino = (this.fechaTermino.getDate() <= 9) ? '0' + (this.fechaTermino.getDate()) : this.fechaTermino.getDate();

        let mes = (this.fechaInicio.getMonth() + 1 <= 9) ? '0' + (this.fechaInicio.getMonth() + 1) : this.fechaInicio.getMonth() + 1;
        let mesTermino = (this.fechaTermino.getMonth() + 1 <= 9) ? '0' + (this.fechaTermino.getMonth() + 1) : this.fechaTermino.getMonth() + 1;

        if (this.seccionSelect == 0) {

          this.secciones.forEach(element => {
            this.seccionSelectFinal.push(element.sec_seccion);
            this.seccionSelectFinal.push(element.sec_id);
          });

        } else {

          this.secciones.forEach(element => {
            if (element.sec_id == this.seccionSelect) {
              this.seccionSelectFinal.push(element.sec_seccion);
              this.seccionSelectFinal.push(element.sec_id);
            }
          });
        }

        let param = {
          cliente: this.cliente,
          sucursal: this.sucursalSelect,
          seccion: this.seccionSelectFinal,
          fechaInicio: this.fechaInicio.getFullYear() + '-' + mes + '-' + dia + '' + 'T00:00:00',
          fechaTermino: this.fechaTermino.getFullYear() + '-' + mesTermino + '-' + diaTermino + '' + 'T23:59:00',
          inicio: fechaInicio,
          termino: fechaTermino
        }

        this.showCargando();

        this.reportesService.getReporteSeccion(param)
          .subscribe(dataReporte => {

            this.hideCargando();

            if (dataReporte == null || dataReporte.resumen == null) {
              this.ux.alerta(dataReporte);
            } else if (dataReporte.resumen.length > 0) {

              this.rowsCasos = dataReporte.resumen;

              for (let index = 0; index < this.rowsCasos.length; index++) {

                if (this.rowsCasos[index].promedio != null) {

                  this.rowsCasos[index].promedio = parseFloat(this.rowsCasos[index].promedio).toFixed(2);

                  var x = this.rowsCasos[index].promedio;
                  let int_part = Math.trunc(x);
                  let float_part = x.split(".")[1]

                  if (int_part > 0) {
                    this.rowsCasos[index].promedio = int_part + " min - " + float_part + " sg"
                  } else {
                    this.rowsCasos[index].promedio = float_part + " sg"
                  }
                } else {
                  this.rowsCasos[index].promedio = "0 sg";
                }

                this.rowsCasos[index].rendimiento = (this.rowsCasos[index].rendimiento != null) ? parseFloat(this.rowsCasos[index].rendimiento).toFixed(2) : "0"

              }

              if (this.rowsCasos[0].promedioRetiro != null) {

                this.rowsCasos[0].promedioRetiro = parseFloat(this.rowsCasos[0].promedioRetiro).toFixed(2);

                var x1 = this.rowsCasos[0].promedioRetiro;
                let int_part = Math.trunc(x1);
                let float_part = x1.split(".")[1]

                if (int_part > 0) {
                  this.rowsCasos[0].promedioRetiro = int_part + " min - " + float_part + " sg"
                } else {
                  this.rowsCasos[0].promedioRetiro = float_part + " sg"
                }

              } else {
                this.rowsCasos[0].promedioRetiro = "0 sg";
              }

              this.rowsCasos[0].rendimientoRetiro = (this.rowsCasos[0].rendimientoRetiro != null) ? parseFloat(this.rowsCasos[0].rendimientoRetiro).toFixed(2) : "0"

              this.registrosCasos = this.registrosService.mapReporteMonitoreoCasos(dataReporte.casos);
              //this.registrosCasos = this.registrosService.deleteNullCasos(this.registrosCasos);

              this.mostrarTabla = true;

            } else {
              this.mostrarTabla = false;
              this.mostrarError('Advertencia', 'No se han encontrado registros en el rango de fecha seleccionado.');
            }

          }, err => {

            this.hideCargando();
            console.log('Error al invocar a reportesService.getReporteSeccion: ', err);

          });

      } else {
        this.mostrarError('Advertencia', 'La fecha de inicio debe ser menor o igual a la fecha de término');
      }

    } else {
      this.mostrarError('Error', 'Favor debe ingresar una sucursal, sección, fecha de inicio y fecha de término');
    }
  }

  strDate(num: number) {
    return ('0' + num).substr(-2);
  }

  generarCSV() {

    var csv = 'ID;Sucursal;Sección;Botón;Estado;Llamada Calificación;Llamada Tiempo (Sg);Preparación Calificación;Preparación Tiempo (sg);Llamada Fecha; Llamada Hora;1er Cancelar Fecha;1er Cancelar Hora;2do Cancelar Fecha;2do Cancelar Hora \n';

    this.registrosCasos.forEach(function (row, cont) {
      if (row != null) {

        let [fechaStringInicio, horaStringInicio] = row.fechaInicioAccionString.split(' - ');
        let [fechaStringTermino, horaStringTermino] = row.fechaTerminoAccionString.split(' - ');
        let [fechaStringFinalizado, horaStringFinalizado] = row.fechaFinalizado.split(' - ');

        fechaStringTermino = (fechaStringTermino == null) ? '-' : fechaStringTermino;
        horaStringTermino = (horaStringTermino == null) ? '-' : horaStringTermino;
        fechaStringFinalizado = (fechaStringFinalizado == null) ? '-' : fechaStringFinalizado;
        horaStringFinalizado = (horaStringFinalizado == null) ? '-' : horaStringFinalizado;
        

        csv += (cont + 1) + ';' + row.sucursal + ';' + row.seccion + ';' + row.boton + ';' + row.estado + ';' + row.caso + ';' + row.dif + ';' + row.casoPreparacion + ';' + row.difSgteSgte + ';' + fechaStringInicio + ';' + horaStringInicio + ';' + fechaStringTermino + ';' + horaStringTermino + ';' + fechaStringFinalizado + ';' + horaStringFinalizado;
        csv += '\n';
      }
    });

    this.generarDescarga(csv);
    this.ux.notificacion('Informe generado satisfactoriamente.', 'success');
  }

  generarDescarga(csv) {
    const f = new Date();
    const fecha = this.strDate(f.getUTCDate()) + '-' + this.strDate(f.getUTCMonth()) + '-' + f.getUTCFullYear() + '_' +
      this.strDate(f.getUTCHours()) + '_' + this.strDate(f.getUTCMinutes()) + '_' + this.strDate(f.getUTCSeconds());

    // console.log(csv);
    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'reporte_seccion_' + this.seccion + " " + fecha + '.csv';
    hiddenElement.click();
  }


  //  *********************************** FUNCIONES DE VISTA ***********************************


  onSucursalChange(sucursal) {
    if (sucursal !== '' && sucursal !== -1) {
      this.getSeccionesSucursal(this.cliente, this.sucursalSelect, (err, dataReporte) => {
        if (err) {
          this.ux.alerta(err.message);
        } else {
          this.secciones = dataReporte;
        }
      });
    }
  }

  getSeccionesSucursal(cliente: number, sucural: number, callback) {
    this.showCargando();

    this.seccionesService.getSeccionesSucursal(this.cliente, this.sucursalSelect)
      .subscribe(dataReporte => {

        this.hideCargando();
        if (dataReporte.status === 'ERROR') {
          callback(dataReporte.error, null);
        } else if (dataReporte.status === 'OK') {
          callback(null, dataReporte.data);
        }

      }, err => {
        this.hideCargando();
        console.log('Error al invocar a seccionesService.getSeccionesSucursal: ', err);
      });
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

  mostrarError(tipo, msg) {

    this.tipoError = tipo

    switch (this.tipoError) {
      case 'Error':
        this.iconError = "fa fa-close";
        this.panelError = "alert-danger";

        break;

      case 'Advertencia':

        this.iconError = "fa fa-exclamation-circle";
        this.panelError = "alert-warning";

        break;

    }

    this.msgError = msg
    this.showError = true;
  }

  ocultarError() {
    this.showError = false;
  }

  setMenuActive(menu) {

    switch (menu) {
      case "registros":
        this.router.navigateByUrl('/registros');
        break;

      case "reporteSucursales":
        this.router.navigateByUrl('/reporteSucursales');
        break;

      case "reporteSecciones":
        this.router.navigateByUrl('/reporteSecciones');
        break;

      case "reporteBotones":
        this.router.navigateByUrl('/reporteBotones');
        break;

      case "reporteResumen":
        this.router.navigateByUrl('/reporteResumen');
        break;

      case "reporteGraficos":
        this.router.navigateByUrl('/reporteGraficos');
        break;

    }

  }

}
