import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InvocaService } from '../../project/services/invoca.service';
import { FormatoFechaHoraPipe } from '../pipe/formato-fecha-hora.pipe';
import { GlobalVar } from '../../../../config';

@Injectable()

export class ReportesService {

  private url_enviarReporteHoy;
  private url_enviarReporte;
  private url_crearReporte;

  constructor(private invoke: InvocaService, private http: HttpClient) {

    this.url_enviarReporteHoy = GlobalVar.URL_BASE + 'reportes/enviarReporteHoy';
    this.url_enviarReporte = GlobalVar.URL_BASE + 'reportes/enviarReporte';
    this.url_crearReporte = GlobalVar.URL_BASE + 'reportes/crearReporte';
  }

  private static handleError(error: any): Promise<any> {
    console.error('Ha ocurrido un error: ', error);
    return Promise.reject(error.message || error);
  }

  getReporteResumen(params?: any): Observable<any> {
    return this.invoke.httpInvoke("reportes/getReporteResumen", params);
  }

  getReporteSucursal(params?: any): Observable<any> {
    return this.invoke.httpInvoke("reportes/getReporteSucursal", params);
  }

  getReporteSeccion(params?: any): Observable<any> {
    return this.invoke.httpInvoke("reportes/getReporteSeccion", params);
  }

  getReporteBotones(params?: any): Observable<any> {
    return this.invoke.httpInvoke("reportes/getReporteBotones", params);
  }

  getReporteResumenGrafico(params?: any): Observable<any> {
    return this.invoke.httpInvoke("reportes/getReporteResumenGrafico", params);
  }

  getEstadisticasGenerales(params?: any): Observable<any> {
    return this.invoke.httpInvoke("reportes/getEstadisticasGenerales", params);
  }

  getEstadisticas(params?: any): Observable<any> {
    return this.invoke.httpInvoke("reportes/getEstadisticas", params);
  }

  getEstadisticasPorDia(params?: any): Observable<any> {
    return this.invoke.httpInvoke("reportes/getEstadisticasPorDia", params);
  }

  getEstadisticasPorHora(params?: any): Observable<any> {
    return this.invoke.httpInvoke("reportes/getEstadisticasPorHora", params);
  }

  enviarReporteHoy(param): Observable<any> {
    return this.http
      .post(this.url_enviarReporteHoy, { arg: JSON.stringify(param) })
      .pipe(map(data => data))
      .pipe(catchError(ReportesService.handleError));
  }

  enviarReporte(param): Observable<any> {
    return this.http
      .post(this.url_enviarReporte, { arg: JSON.stringify(param) })
      .pipe(map(data => data))
      .pipe(catchError(ReportesService.handleError));
  }

  crearReporte(param): Observable<any> {
    return this.http
      .post(this.url_crearReporte, { arg: JSON.stringify(param) })
      .pipe(map(data => data))
      .pipe(catchError(ReportesService.handleError));
  }

  // ************* SECCION MAPEOS *************


  mapReporteSucursal(m: any): any[] {
    return m.map(this.toReporteSucursal);
  }

  toReporteSucursal(r: any): any {

    let m = <any>({
      nombreBtn: r.nombreBtn,
      accion: r.accion,
      cantidad: r.cantidad,
      seccion: r.nombreSeccion,
    });
    return m;
  }

  mapReporteSeccion(m: any): any[] {
    return m.map(this.toReporteSeccion);
  }

  toReporteSeccion(r: any): any {

    let m = <any>({
      seccion: r.sec_seccion,
      nombreBtn: r.bot_nombre,
      accion: r.reg_accion,
      fecha: FormatoFechaHoraPipe.formatoFecha(r.reg_fecha),
    });
    return m;
  }

  mapReporteBotones(m: any): any[] {
    return m.map(this.toReporteBotones);
  }

  toReporteBotones(r: any): any {

    let m = <any>({
      nombreBtn: r.bot_nombre,
      accion: r.reg_accion,
      fecha: FormatoFechaHoraPipe.formatoFecha(r.reg_fecha),
    });
    return m;
  }

  mapReporteBotonesRegistros(m: any): any[] {
    return m.map(this.toReporteBotonesRegistros);
  }

  toReporteBotonesRegistros(r: any): any {

    let m = <any>({
      nombreBtn: r.nombreBtn,
      accion: r.accion,
      fecha: FormatoFechaHoraPipe.formatoFechaTerminoNull(r.fecha),
    });
    return m;
  }



}

