import { Component, OnInit, TemplateRef, ViewChild, Input, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegistrosService } from '../../services/registros.service';
import { ReportesService } from '../../services/reportes.service';
import { SeccionesService } from '../../services/secciones.service';
import { UxService } from '../../services/ux.service';

import { ngxLoadingAnimationTypes } from 'ngx-loading';
import { log } from 'util';
import { Router } from '@angular/router';
import { ReporteMonitoreo } from '../../entities/ReporteMonitoreo';
import { SucursalesService } from '../../services/sucursales.service';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';

@Component({
  selector: 'app-registros-generales',
  templateUrl: './registrosGenerales.component.html',
  styleUrls: ['./registrosGenerales.component.scss'],
  providers: [],
})
export class RegistrosGeneralesComponent implements OnInit {

  cliente: number;
  usuario: number;
  seccion: any;
  @ViewChild('tablaRegistros') table: any;
  rows: any = [];
  registrosMonitoreo: Array<ReporteMonitoreo> = [];
  registrosMonitoreoCasos: Array<any> = [];
  fechaActualizacion: Date;
  reporteForm: FormGroup;
  submitted = false;
  idTime: any;
  sucursales: any = [];
  secciones: any = [];
  sucursalSelect: number = -1;
  seccionSelect: number = -1;
  isCelular: boolean = false;

  showError: boolean = false;
  tipoError: string = "";
  msgError: string = "";
  iconError: string = "";
  panelError: string = "";

  // Variables componente cargando  

  loading = false;
  public primaryColour = PrimaryWhite;
  public secondaryColour = SecondaryGrey;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public loadingTemplate: TemplateRef<any>;
  public config = { animationType: ngxLoadingAnimationTypes.none, primaryColour: this.primaryColour, secondaryColour: this.secondaryColour, tertiaryColour: this.primaryColour, backdropBorderRadius: '3px' };

  constructor(
    private formBuilder: FormBuilder,
    private registrosService: RegistrosService,
    private reportesService: ReportesService,
    private sucursalesService: SucursalesService,
    private seccionesService: SeccionesService,
    private router: Router,
    private ux: UxService
  ) {

    var ua = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      // this.mostrarMsg("success", "Éxito", "Entro un celular.");
      this.isCelular = true;
    }

    this.cliente = parseInt(localStorage.getItem('usu_cliente'), 10);
    this.usuario = parseInt(localStorage.getItem('usu_id'), 10);

    var classFondo = document.getElementsByClassName("fondo")[0];

    if (classFondo != null && classFondo != undefined) {
      classFondo.className = "sinFondo";
    }

    var currentUtcTime = new Date();
    this.fechaActualizacion = (this.isCelular) ? currentUtcTime : new Date(currentUtcTime.toLocaleString('en-CL', { timeZone: 'America/Santiago' }));

    this.getSucursales();

  }

  ngOnDestroy() {
    if (this.idTime) {
      clearInterval(this.idTime);
    }
  }

  ngOnInit() {

    this.reporteForm = this.formBuilder.group({
      tipoReporte: ['', [Validators.required]],
      seccion: ['']
    });

  }

  filtrarRegistros() {


    if (this.sucursalSelect != null && this.sucursalSelect !== -1 &&
      this.seccionSelect != null && this.seccionSelect !== -1) {

      this.reporteMonitoreoLog(this.cliente, (data, err) => {
        if (err) {
          this.ux.alerta(err.message);
        } else {

          // this.registrosMonitoreo = this.registrosService.mapReporteMonitoreoLog(data.registros);
          // this.registrosMonitoreo = this.registrosService.deleteNulls(this.registrosMonitoreo);

          this.registrosMonitoreoCasos = this.registrosService.mapReporteMonitoreoCasos(data.registrosCasos);
          //this.registrosMonitoreoCasos = this.registrosService.deleteNullCasos(this.registrosMonitoreoCasos);

          this.ocultarError();

        }
      });

    } else {
      this.mostrarError('Error', 'Favor debe ingresar una sucursal y sección');
    }

  }

  reporteMonitoreoLog(cliente: number, callback) {

    this.showCargando();

    let mes = ((this.fechaActualizacion.getMonth() + 1) <= 9) ? '0' + (this.fechaActualizacion.getMonth() + 1) : this.fechaActualizacion.getMonth() + 1;
    let dia = (this.fechaActualizacion.getDate() <= 9) ? '0' + (this.fechaActualizacion.getDate()) : this.fechaActualizacion.getDate();

    let param = {
      cliente: cliente,
      usuario: this.usuario,
      idSucursal: this.sucursalSelect,
      idSeccion: this.seccionSelect,
      fechaInicio: this.fechaActualizacion.getFullYear() + '-' + mes + '-' + dia + '' + 'T00:00:00',
      fechaTermino: this.fechaActualizacion.getFullYear() + '-' + mes + '-' + dia + '' + 'T23:59:00',
    }

    if (this.sucursalSelect != null && this.seccionSelect != null) {

      this.registrosService.reporteMonitoreoLog(param)
        .subscribe(data => {

          this.hideCargando();

          if (data.status === 'ERROR') {
            callback(null, data.error);
          } else if (data.status === 'OK') {

            var currentUtcTime = new Date();
            this.fechaActualizacion = (this.isCelular) ? currentUtcTime : new Date(currentUtcTime.toLocaleString('en-CL', { timeZone: 'America/Lima' }));

            callback(data.data, null);
          }
        }, err => {
          this.hideCargando();
          console.log('Error al invocar a registrosService.reporteMonitoreoLog: ', err);
        });
    } else {
      this.hideCargando();
    }

  }

  getSucursales() {

    this.showCargando();

    this.sucursalesService.getSucursalesUser(this.cliente, this.usuario)
      .subscribe(dataSucursales => {

        if (dataSucursales.status === 'ERROR') {
          console.log('Error al invocar a botonesService.getSucursalesUser: ', dataSucursales.error);
        } else if (dataSucursales.status === 'OK') {
          this.sucursales = dataSucursales.data;
        }

      }, err => {
        this.hideCargando();
        console.log('Error al invocar a sucursalesService.getSucursalesUser: ', err);
      }, () => {

        if (this.sucursales.length <= 3) {

          // this.reporteMonitoreoLog(this.cliente, (data, err) => {

          //   if (err) {
          //     this.ux.alerta(err.message);
          //   } else {
          //     this.registrosMonitoreoCasos = this.registrosService.mapReporteMonitoreoCasos(data.registrosCasos);
          //     //this.registrosMonitoreoCasos = this.registrosService.deleteNullCasos(this.registrosMonitoreoCasos);

          //   }

          // });

          this.hideCargando();

          this.idTime = setInterval(() => {

            this.reporteMonitoreoLog(this.cliente, (data, err) => {
              if (err) {
                this.ux.alerta(err.message);
              } else {
                this.registrosMonitoreoCasos = this.registrosService.mapReporteMonitoreoCasos(data.registrosCasos);
                //this.registrosMonitoreoCasos = this.registrosService.deleteNullCasos(this.registrosMonitoreoCasos);
              }
            });

          }, 120000);

        } else {
          this.hideCargando();
        }

      });
  }

  onSucursalChange(event) {

    this.seccionSelect = (this.sucursalSelect == -1) ? -1 : null

    this.sucursalSelect = event;
    this.registrosMonitoreo = [];
    this.secciones = [];

    this.getSeccionesSucursal((err, data) => {
      if (err) {
        this.ux.alerta(err.message);
      } else {
        this.secciones = data;
      }
    });
  }

  onSeccionChange(event) {
    this.seccionSelect = event;
  }

  getSeccionesSucursal(callback) {
    this.showCargando();
    this.seccionesService.getSeccionesSucursal(this.cliente, this.sucursalSelect)
      .subscribe(dataReporte => {
        this.hideCargando();
        if (dataReporte.status === 'ERROR') {
          callback(dataReporte.error, null);
        } else if (dataReporte.status === 'OK') {
          callback(null, dataReporte.data);
        }
      }, err => {
        this.hideCargando();
        console.log('Error al invocar a seccionesService.getSeccionesSucursal: ', err);
      });
  }


  //  *********************************** GENERAR REPORTE ***********************************


  descargarCSV(tipo) {

    this.reporteMonitoreoLog(this.cliente, (dataReporte, err) => {
      if (err) {
        this.ux.alerta(err.message);
      } else {
        if (dataReporte.length === 0) {

          this.ux.notificacion('No hay registros para generar el reporte.', 'warning');
        } else {

          // this.registrosMonitoreo = this.registrosService.mapReporteMonitoreoLog(dataReporte.registros);
          // this.registrosMonitoreo = this.registrosService.deleteNulls(this.registrosMonitoreo);

          this.registrosMonitoreoCasos = this.registrosService.mapReporteMonitoreoCasos(dataReporte.registrosCasos);
          //this.registrosMonitoreoCasos = this.registrosService.deleteNullCasos(this.registrosMonitoreoCasos);          

          var csv = '';

          if (tipo == 'log') {
            var csv = 'ID;Sucursal;Sección;Botón;Fecha - Hora;Acción\n';
            // this.registrosMonitoreo.forEach(function (row, cont) {
            //   if (row != null) {
            //     csv += (cont + 1) + ';' + row.sucursal + ';' + row.seccion + ';' + row.nombreBtn + ';' + row.fechaInicioAccionString + ';' + row.accion;
            //     csv += '\n';
            //   }
            // });

          } else if (tipo == 'casos') {

            var csv = 'ID;Sucursal;Sección;Botón;Estado;Llamada Calificación;Llamada Tiempo (Sg);Preparación Calificación;Preparación Tiempo (sg);Llamada Fecha; Llamada Hora;1er Cancelar Fecha;1er Cancelar Hora;2do Cancelar Fecha;2do Cancelar Hora \n';
            this.registrosMonitoreoCasos.forEach(function (row, cont) {
              if (row != null) {

                let [fechaStringInicio, horaStringInicio] = row.fechaInicioAccionString.split(' - ');
                let [fechaStringTermino, horaStringTermino] = row.fechaTerminoAccionString.split(' - ');
                let [fechaStringFinalizado, horaStringFinalizado] = row.fechaFinalizado.split(' - ');

                fechaStringTermino = (fechaStringTermino == null) ? '-' : fechaStringTermino;
                horaStringTermino = (horaStringTermino == null) ? '-' : horaStringTermino;
                fechaStringFinalizado = (fechaStringFinalizado == null) ? '-' : fechaStringFinalizado;
                horaStringFinalizado = (horaStringFinalizado == null) ? '-' : horaStringFinalizado;


                csv += (cont + 1) + ';' + row.sucursal + ';' + row.seccion + ';' + row.boton + ';' + row.estado + ';' + row.caso + ';' + row.dif + ';' + row.casoPreparacion + ';' + row.difSgteSgte + ';' + fechaStringInicio + ';' + horaStringInicio + ';' + fechaStringTermino + ';' + horaStringTermino + ';' + fechaStringFinalizado + ';' + horaStringFinalizado;
                csv += '\n';
              }
            });
          }

          this.generarDescarga(csv);
          this.ux.notificacion('Reporte descargado exitósamente.', 'success');
        }
      }
    });
  }

  generarDescarga(csv) {
    const f = new Date();
    const fecha = this.strDate(f.getUTCDate()) + '-' + this.strDate(f.getUTCMonth() + 1) + '-' + f.getUTCFullYear();

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'Reporte monitoreo log (' + fecha + ').csv';
    hiddenElement.click();
  }


  //  *********************************** FUNCIONES DE VISTA ***********************************


  strDate(num: number) {
    return ('0' + num).substr(-2);
  }

  showCargando() {
    this.loading = true;
  }

  hideCargando() {
    this.loading = false;
  }

  setMenuActive(menu) {

    switch (menu) {

      case "registrosGenerales":
        this.router.navigateByUrl('/registrosGenerales');
        break;

      case "registros":
        this.router.navigateByUrl('/registros');
        break;

      case "reporteSucursales":
        this.router.navigateByUrl('/reporteSucursales');
        break;

      case "reporteSecciones":
        this.router.navigateByUrl('/reporteSecciones');
        break;

      case "reporteBotones":
        this.router.navigateByUrl('/reporteBotones');
        break;

      case "reporteResumen":
        this.router.navigateByUrl('/reporteResumen');
        break;

    }

  }

  mostrarError(tipo, msg) {

    this.tipoError = tipo

    switch (this.tipoError) {
      case 'Error':
        this.iconError = "fa fa-close";
        this.panelError = "alert-danger";

        break;

      case 'Advertencia':

        this.iconError = "fa fa-exclamation-circle";
        this.panelError = "alert-warning";

        break;

    }

    this.msgError = msg
    this.showError = true;
  }

  ocultarError() {
    this.showError = false;
  }

}
